import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "KazDevLab": "NomadForce",
      "We offer": "We offer a software development teams or individual software developers according to your needs.",
      "Services": "Services",
      "Technologies": "Technologies",
      "Interaction": "Interaction process",
      "Language": "Русский",
      "Why": "Why NomadForce?",
      "Contact": "Contact",
      "Our Services": "Our Services",
      "Web Development": "Web Development",
      "DevOps": "DevOps",
      "Quality": "Quality Assurance",
      "Software": "Software Architecture",
      "Team": "Dedicated Team",
      "Support": "Support and Maintenance",
      "Web": "Web",
      "Web list": "HTML5, JAVASCRIPT, NODE JS, VUE.JS, JAVA",
      "Digital": "Digital",
      "Digital list": "UX/UI, DESIGN, PRODUCTION",
      "Cloud": "Cloud",
      "Cloud list": "AMAZON, GOOGLE, AZURE, BIG DATA, DATA SCIENCE",
      "Testing": "Testing",
      "Testing list": "DESIGN, FUNCTIONAL, AUTOMATION, PERFORMANCE",
      "Offer": "Offer",
      "Offer desc": "Signing of agreements and initiation of work. We propose a team or an individual software  developer",
      "Agreement": "Agreement and start",
      "Agreement desc": "Signing of agreements and initiation of work",
      "Request": "Request",
      "Request desc": "Individual software developer or team reques",
      "Interview": "Interview and clarification",
      "Interview desc": "Developer interviews, project discussion",
      "Reports": "Reports",
      "Reports desc": "Payment for software development services. Periodic completion reports",
      "Payment": "Payment",
      "Payment desc": "Payment for software development services",
      "Planning": "Planning and execution",
      "Planning desc": "Splitting work into sizable chunks and execution according to scrum ceremonies",
      "Acceptance": "Acceptance of completed work",
      "Acceptance desc": "Delivery of work, handing off code and other produced assets",
      "Full-cycle": "Full-cycle Development",
      "Full-cycle desc": "We are a full-cycle software development company. It means we can handle everything from the business analysis of the initial idea and design to development, implementation, and maintenance of the final product.",
      "Trust": "Trust & Transparency",
      "Trust desc": "Trust and Transparency are at the core of NomadForce’s corporate culture. We don’t hold our thoughts and insights back because we know they can make a difference. You can be sure that we will be honest with you and will expect you to be honest with us.",
      "Business": "Business Mindset",
      "Business desc": "Software development experts can deliver efficient software while business experts can polish your idea considering market realities to make it competitive. We proudly combine both expertise and can create a competitive digital product.",
      "Industries": "Industries",
      "Extensive": "Extensive experience",
      "Extensive desc": "We have worked on about 50 projects that have helped our clients achieve their business goals. Worked on projects for Government agencies, Banks, financial organizations, integration with MasterPass, implementation of authorization modules and much more",
      "Extensive add": "50+ successful projects",
      "IndustryFocus": "Industry-Focused specialists",
      "IndustryFocus desc": "We provide developers who have experience from 2-16 years",
      "Customer L": "Customer Loyalty",
      "Customer L desc": "High standards, efficient project implementation and the ability to develop any IT solution allow us to be widely recognized.",
      "Contact Us": "Contact Us",
      "Astana": "st.Almaty 7, Astana, Kazakhstan",
      "CEO": "CEO - Mamyrzhanov Askhat",
      "Mobile": "Mobile +7 701 773 67 78",
      "E-mail": "E-mail: a.mamyrzhanov@gmail.com"
    }
  },
  ru: {
    translation: {
      "KazDevLab": "NomadForce",
      "We offer": "Мы предлагаем команду разработчиков программного обеспечения или отдельных разработчиков программного обеспечения в соответствии с вашими потребностями",
      "Services": "Сервисы",
      "Technologies": "Технологии",
      "Interaction": "Процесс взаимодействия",
      "Why": "Почему NomadForce?",
      "Language": "English",
      "Contact": "Контакты",
      "Our Services": "Наши сервисы",
      "Web Development": "Веб-разработка",
      "DevOps": "DevOps",
      "Quality": "Гарантия качества",
      "Software": "Архитектура программного обеспечения",
      "Team": "Выделенная команда",
      "Support": "Поддержка и обслуживание",
      "Web": "Web",
      "Web list": "HTML5, JAVASCRIPT, NODE JS, VUE.JS, JAVA",
      "Digital": "Digital",
      "Digital list": "UX/UI, ДИЗАЙН, ПРОДАКШН",
      "Cloud": "Cloud",
      "Cloud list": "AMAZON, GOOGLE, AZURE, BIG DATA, DATA SCIENCE",
      "Testing": "Тестирование",
      "Testing list": "ДИЗАЙН, ФУНКЦИОНАЛ, АВТОМАТИЗАЦИЯ, ПРОИЗВОДИТЕЛЬНОСТЬ",
      "Offer": "Предложение",
      "Offer desc": "Подписание договоров и начало работы. Предлагаем команду или индивидуального разработчика ПО",
      "Agreement": "Соглашение и начало",
      "Agreement desc": "Подписание договоров и начало работ",
      "Request": "Запрос",
      "Request desc": "Индивидуальный разработчик программного обеспечения или групповые запросы",
      "Interview": "Интервью и разъяснения",
      "Interview desc": "Интервью с разработчиками, обсуждение проекта, отчеты",
      "Reports": "Отчеты",
      "Reports desc": "Промежуточные отчеты о проделонной работе",
      "Payment": "Оплата",
      "Payment desc": "Оплата услуг по разработке программного обеспечения",
      "Planning": "Планирование и исполнение",
      "Planning desc": "Разделение работы на значительные спринты и выполнение в соответствии с правилами скрама",
      "Acceptance": "Прием выполненных работ",
      "Acceptance desc": "Сдача работы, передача кода и других произведенных активов",
      "Full-cycle": "Разработка полного цикла",
      "Full-cycle desc": "Мы — компания по разработке программного обеспечения полного цикла. Это означает, что мы можем справиться со всем, от бизнес-анализа первоначальной идеи и дизайна до разработки, внедрения и обслуживания конечного продукта.",
      "Trust": "Доверие и прозрачность",
      "Trust desc": "Доверие и прозрачность лежат в основе корпоративной культуры NomadForce. Мы не скрываем свои мысли и идеи, потому что знаем, что они могут изменить ситуацию. Вы можете быть уверены, что мы будем честны с вами и ожидаем, что вы будете честны с нами.",
      "Business": "Деловое мышление",
      "Business desc": "Эксперты по разработке программного обеспечения могут предоставить эффективное программное обеспечение, а бизнес-эксперты могут отшлифовать вашу идею с учетом рыночных реалий, чтобы сделать ее конкурентоспособной. Мы с гордостью объединяем оба опыта и можем создать конкурентоспособный цифровой продукт.",
      "Industries": "Отрасли",
      "Extensive": "Опыт работы",
      "Extensive desc": "Мы работали над  50 проектами, которые помогли нашим клиентам достичь своих бизнес-целей. Интеграция с эквайрингами банков второго уровня,  MasterPass, шлюз 'электронного правительства', Smart bridge,  внедрением модулей авторизации посредством НУЦ РК, а также по отраслевым направлениям клиентов.",
      "Extensive add": "50+ успешных проектов",
      "IndustryFocus": "Опыт специалистов в индустрии",
      "IndustryFocus desc": "Команда разработчиков с опытом от 2-16 лет",
      "Customer L": "Доверие клиентов",
      "Customer L desc": "Высокие стандарты, эффективная реализация проектов и возможность разработать любое ИТ-решение позволяют нам получить широкое признание.",
      "Contact Us": "Связаться с нами",
      "Astana": "ул. Алматы 7, Астана, Казахстан",
      "CEO": "CEO - Мамыржанов Асхат",
      "Mobile": "Телефон +7 701 773 67 78",
      "E-mail": "E-mail: a.mamyrzhanov@gmail.com"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;
import './App.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WebLogo } from './images/web-01.svg';
import { ReactComponent as ArchitectureLogo } from './images/architecture.svg';
import { ReactComponent as DevopsLogo } from './images/devops.svg';
import { ReactComponent as SupportLogo } from './images/support.svg';
import { ReactComponent as QualityLogo } from './images/quality.svg';
import { ReactComponent as TeamLogo } from './images/team.svg';

function App() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('en');

  const changeLanguage = () => {
    if (language === 'en') {
      setLanguage('ru')
      i18n.changeLanguage('ru')
    } else {
      setLanguage('en')
      i18n.changeLanguage('en')
    }
  }
  return (
    <div className="App">
      <section className="section1" id='home'>
        <header className="App-header">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img src={require('./images/logo-text.jpg')} alt="logo" style={{marginLeft: '14px', fontFamily: 'cursive'}}/>
          </div>
          <nav className="navbar navbar-expand-md" >
            <div className="">
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mb-2 mb-lg-1">
                  <li className="nav-item" style={{marginLeft: '25px', fontSize: '20px'}}>
                    <a className="nav-link dark-title" aria-current="page" href="#home">{t('Services')}</a>
                  </li>
                  <li className="nav-item" style={{marginLeft: '25px', fontSize: '20px'}}>
                    <a className="nav-link dark-title" href="#technologies">{t('Technologies')}</a>
                  </li>
                  <li className="nav-item" style={{marginLeft: '25px', fontSize: '20px'}}>
                    <a className="nav-link dark-title" href="#interaction">{t('Interaction')}</a>
                  </li>
                  <li className="nav-item" style={{marginLeft: '25px', fontSize: '20px'}}>
                    <a className="nav-link dark-title" href="#why">{t('Why')}</a>
                  </li>
                  <li className="nav-item" style={{marginLeft: '25px', fontSize: '20px'}}>
                    <a className="nav-link dark-title" href="#contact">{t('Contact')}</a>
                  </li>
                  <li className="nav-item" style={{marginLeft: '25px', fontSize: '20px', cursor: 'pointer', display: 'flex', fontFamily: 'cursive'}}>
                    <p className="nav-link dark-title" onClick={() => changeLanguage()}>{t('Language')}</p>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </section>
      <section className='section2'>
        <div className='container-sm section1-text'>
          <p><strong>{t('We offer')}</strong></p>
        </div>
      </section>
      <section className='section3' id='services'>
        <div className='container-sm'>
          <h1 className='h1-title'>{t('Our Services')}</h1>
          <hr class="hr-line-title"></hr>
          <div class="container">
            <div class="row mb-4">
              <div class="col 5 mb-4 mt-4" style={{margin: '1rem'}}>
                <WebLogo class="mb-4" stroke="rgb(14, 14, 15)" style={{ width: '8rem', height: '8rem'}}/>
                <h1>{t('Web Development')}</h1>
              </div>
              <div class="col mb-4 mt-4" style={{margin: '1rem'}}>
                <DevopsLogo class="mb-4" stroke="rgb(14, 14, 15)" style={{ width: '8rem', height: '8rem'}}/>
                <h1>{t('DevOps')}</h1>
              </div>
              <div class="col mb-4 mt-4" style={{margin: '1rem'}}>
                <QualityLogo class="mb-4" stroke="rgb(14, 14, 15)" style={{ width: '8rem', height: '8rem'}}/>
                <h1>{t('Quality')}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col mb-4 mt-4" style={{margin: '1rem'}}>
                <ArchitectureLogo class="mb-4" stroke="rgb(14, 14, 15)" style={{ width: '8rem', height: '8rem'}}/>
                <h1>{t('Software')}</h1>
              </div>
              <div class="col mb-4 mt-4" style={{margin: '1rem'}} >
                <TeamLogo class="mb-4" stroke="rgb(14, 14, 15)" style={{ width: '8rem', height: '8rem'}}/>
                <h1>{t('Team')}</h1>
              </div>
              <div class="col mb-4 mt-4" style={{margin: '1rem'}}>
                <SupportLogo class="mb-4" stroke="rgb(14, 14, 15)" style={{ width: '8rem', height: '8rem'}}/>
                <h1>{t('Support')}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section4' id='technologies'>
        <div style={{padding: '5rem 15%'}}>
          <h1 className='h1-title'>{t('Technologies')}</h1>
          <hr class="hr-line-title-green"></hr>
            <div class="row">
              <div class="col">
                <div class="card mb-4 shadow p-3" style={{width: "90%", marginRight: '5rem',}}>
                  <div class="card-body card-body-custom">
                    <h1 class="card-title">Backend</h1>
                    <hr class="hr-line"></hr>
                    <p class="card-text">
                      <strong>Basis:</strong><em>Java (Spring: Core, MVC, Security, Data, Boot, Cloud), REST API, SOAP, Java 11+, ASP.NET</em>
                    </p>
                    <p class="card-text">
                      <strong>Core Database:</strong><em>Hibernate, MySql, PostgreSql, Oracle, Reddis, MongoDb, S3</em>
                    </p>
                    <p class="card-text">
                      <strong>Authorization:</strong><em>Keycloak, JWT, OAuth 2.0</em>
                    </p>
                    <p class="card-text">
                      <strong>Queues:</strong><em>RabbitMQ, Kafka.</em>
                    </p>
                    <p class="card-text">
                      <strong>Microservices:</strong><em>Kubernetes, Docker</em>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-4 shadow p-3" style={{width: "70%", marginRight: '5rem'}}>
                  <div class="card-body card-body-custom">
                    <h3 class="card-title">Frontend</h3>
                    <hr class="hr-line"></hr>
                    <p class="card-text">
                      <strong>Basis:</strong><em> JavaScript, HTML5, CSS3, ReactJS, VueJS, Angular 6+</em>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-4 shadow p-3" style={{width: "90%", marginRight: '5rem'}}>
                  <div class="card-body card-body-custom">
                    <h3 class="card-title">Tests & CI/CD </h3>
                    <hr class="hr-line"></hr>
                    <p class="card-text">
                      <strong>Tests:</strong><em>JUnit, Mockito, Selenium </em>
                    </p>
                    <p class="card-text">
                      <strong>Version control: </strong><em>Git, BitBucket</em>
                    </p>
                    <p class="card-text">
                      <strong>Compilation: </strong><em>Maven, Gradle</em>
                    </p>
                    <p class="card-text">
                      <strong>Logs and documentation: </strong><em>Kibana, Swagger</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section id='interaction'>
          <h1 className='h1-title mt-5'>{t('Interaction')}</h1>
          <hr class="hr-line-title"></hr>
          <div className='' style={{padding: '5rem 15%'}}>
          <div class="row mb-3">
            <div class="col steps">
              <div class="numberCircle2">I</div>
              <hr class="hr-line-steps"></hr>
            </div>
            <div class="col steps">
              <div class="numberCircle2">II</div>
              <hr class="hr-line-steps"></hr>
            </div>
            <div class="col steps">
              <div class="numberCircle2">III</div>
              <hr class="hr-line-steps"></hr>
            </div>
            <div class="col steps">
              <div class="numberCircle2">IV</div>
              <hr class="hr-line-steps"></hr>
            </div>
          </div>
          <div class="row mb-5 text-start">
            <div class="col">
                <h3 class="card-title mb-3">{t('Offer')}</h3>
                <h5 class="explain-text"><em>{t('Offer desc')}</em></h5>
              </div>
            <div class="col">
              <h3 class="card-title mb-3">{t('Agreement')}</h3>
              <h5 class="explain-text"><em>{t('Agreement desc')}</em></h5>
            </div>
            <div class="col">
              <h2 class="card-title mb-3">{t('Request')}</h2>
              <h5 class="explain-text"><em>{t('Request desc')}</em></h5>
            </div>
            <div class="col">
              <h2 class="card-title mb-3">{t('Interview')}</h2>
              <h5 class="explain-text"><em>{t('Interview desc')}</em></h5>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col steps">
              <div class="numberCircle2">V</div>
              <hr class="hr-line-steps"></hr>
            </div>
            <div class="col steps">
              <div class="numberCircle2">VI</div>
              <hr class="hr-line-steps"></hr>
            </div>
            <div class="col steps">
              <div class="numberCircle2">VII</div>
              <hr class="hr-line-steps"></hr>
            </div>
            <div class="col steps">
              <div class="numberCircle2">VIII</div>
              <hr class="hr-line-steps"></hr>
            </div>
          </div>
          <div class="row text-start">
            <div class="col">
                <h3 class="card-title mb-3">{t('Reports')}</h3>
                <h5 class="explain-text"><em>{t('Reports desc')}</em></h5>
            </div>
            <div class="col">
              <h3 class="card-title mb-3">{t('Payment')}</h3>
              <h5 class="explain-text"><em>{t('Payment desc')}</em></h5>
            </div>
            <div class="col">
              <h3 class="card-title mb-3">{t('Planning')}</h3>
              <h5 class="explain-text"><em>{t('Planning desc')}</em></h5>
            </div>
            <div class="col">
              <h3 class="card-title mb-3">{t('Acceptance')}</h3>
              <h5 class="explain-text"><em>{t('Acceptance desc')}</em></h5>
            </div>
          </div>
        </div>
      </section>
      <section className='section5 mt-5' id='why'>
        <h1 className='h1-title mt-5' style={{color: "#fff"}}>{t('Why')}</h1>
        <hr class="hr-line-title"></hr>
        <div class='row mt-5' style={{textAlign: '-webkit-center'}}>
          <div class="col">
            <div class="card shadow" style={{width: "calc(70%)"}}>
              <h1 class="card-header-text mt-5" style={{fontWeight: '600'}}>{t('Full-cycle')}</h1>
              <h3 class="card-text-why"><em>{t('Full-cycle desc')}</em></h3>  
            </div>
          </div>
          <div class="col">
            <div class="card shadow" style={{width: "calc(70%)"}}>
              <h1 class="card-header-text mt-5" style={{fontWeight: '600'}}>{t('Trust')}</h1>
              <h3 class="card-text-why"><em>{t('Trust desc')}</em></h3>  
            </div>
          </div>
          <div class="col">
            <div class="card shadow" style={{width: "calc(70%)"}}>
              <h1 class="card-header-text mt-5" style={{fontWeight: '600'}}>{t('Business')}</h1>
              <h3 class="card-text-why"><em>{t('Business desc')}</em></h3>  
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div class="row justify-content-md-center">
          <div class="col mb-4 mt-5 col-md-auto">
            <i class="bi-check-circle" style={{fontSize: "3rem", color: "#151957"}} />
          </div>
          <div class="col mb-4 mt-5" style={{width: "4rem"}}>
            <h1 class="display-6">{t('Extensive')}</h1>
          </div>
          <div class="col mt-5 text-start">
            <h3><em>{t('Extensive desc')}</em></h3>
          </div>
        </div>
        <div class="row">
          <div class="col mb-4 mt-5 col-md-auto">
            <i class="bi-check-circle" style={{fontSize: "3rem", color: "#151957"}} />
          </div>
          <div class="col mb-4 mt-5" style={{width: "4rem"}}>
            <h1 class="display-6">{t('IndustryFocus')}</h1>
          </div>
          <div class="col mt-5 text-start">
            <h3><em>{t('IndustryFocus desc')}</em></h3>
          </div>
        </div>
        <div class="row">
          <div class="col mb-4 mt-5 col-md-auto">
            <i class="bi-check-circle" style={{fontSize: "3rem", color: "#151957"}} />
          </div>
          <div class="col mb-4 mt-5" style={{width: "4rem"}}>
            <h1 class="display-6">{t('Customer L')}</h1>
          </div>
          <div class="col mt-5 text-start">
            <h3><em>{t('Customer L desc')}</em></h3>
          </div>
        </div>
      </section>
      <section className='section3' id='contact'>
        <div className='container-sm'>
          <h1 className='h1-title mb-4'>{t('Contact Us')}</h1>
          <div class='container-sm'>
            <div class="card mb-4 shadow p-3 bg-light" >
              <div class="card-body mb-4">
                <div class="contact-div">
                  <i class="bi bi-geo-alt-fill" style={{fontSize: "2rem", color: "#151957", marginInline: '10px'}}></i>
                  <h2><em>{t('Astana')}</em></h2>
                </div>
                <h2>{t('Mobile')}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
